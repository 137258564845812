import { AssetManagementCategoryAggregationKind, AssetManagementCategoryType } from "~/graphql/generated/graphql";
import { type TranslationKey } from "~/helpers/common-helpers";

export type CatalogueCategory = {
  id: string;
  name: string;
  parentCategoryId?: Nullable<string>;
  order?: number;
  categoryType?: Nullable<AssetManagementCategoryType>;
  aggregationKind?: Nullable<AssetManagementCategoryAggregationKind>;
};

export type CatalogueCategoryState = {
  category?: CatalogueCategory;
  expanded: boolean;
  loading: boolean;
  needsRefetch: boolean;
  children: CatalogueCategoryState[];
  parent?: CatalogueCategoryState;
};

export type CatelogueAddCategory = {
  name: string;
  categoryType?: Nullable<AssetManagementCategoryType>;
  aggregationKind?: Nullable<AssetManagementCategoryAggregationKind>;
};

export enum ModalKey {
  add = 1,
  delete = 2,
}

export const getCategoryTypeText = (categoryType?: Nullable<AssetManagementCategoryType>): TranslationKey =>
  categoryType ? `DATAWAREHOUSE_CATALOGUE_CATEGORY_TYPE_${categoryType}` : "NONE";

export const getAggregationKindText = (aggKind: AssetManagementCategoryAggregationKind): TranslationKey =>
  `DATAWAREHOUSE_CATALOGUE_AGGREGATION_KIND_${aggKind}`;

export const getCategoryTypeColor = function (categoryType?: Nullable<AssetManagementCategoryType>) {
  switch (categoryType) {
    case AssetManagementCategoryType.Capex:
      return "--color-magenta-400";
    case AssetManagementCategoryType.Opex:
      return "--color-autumn-400";
    case AssetManagementCategoryType.Noi:
      return "--color-maroon-400";
    case AssetManagementCategoryType.PropertyValue:
      return "--color-cyan-400";
    default:
      return "--color-transparent";
  }
};
