// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_OmQJW{margin-bottom:.5rem}.category_BHmYL,.container_OmQJW{align-items:center;display:flex}.category_BHmYL{border:1px solid var(--color-supportive-3-400);border-radius:var(--border-radius-large);cursor:pointer;flex-direction:row;height:2rem;overflow:hidden}.category_BHmYL:not(:hover) .iconButton_vyDeL{opacity:0}.category_BHmYL:hover{background-color:var(--color-base)}.categoryType_xovX2{border-left:.25rem solid var(--category-type-color);height:100%}.drag_heqxz{align-items:center;background-color:var(--color-supportive-3-100);display:flex;height:100%;justify-content:center;padding:.25rem;width:1.5rem}.iconButton_vyDeL{cursor:pointer;opacity:1;transition:opacity var(--transition-duration-short) ease-in-out}.actions_PGw8I{align-self:center;display:flex;flex:1;flex-direction:row;gap:.25rem;justify-self:flex-end;padding:0 .5rem}.iconButton_vyDeL:first-child{margin-left:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_OmQJW",
	"category": "category_BHmYL",
	"iconButton": "iconButton_vyDeL",
	"categoryType": "categoryType_xovX2",
	"drag": "drag_heqxz",
	"actions": "actions_PGw8I"
};
module.exports = ___CSS_LOADER_EXPORT___;
