import { render, staticRenderFns } from "./DataCategoryDeleteModal.vue?vue&type=template&id=3426dee5"
import script from "./DataCategoryDeleteModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./DataCategoryDeleteModal.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/app/components/Loader/Loader.vue').default})
