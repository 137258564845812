// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".column_Rh1Ve,.row_cxV5E{display:flex}.row_cxV5E{align-items:center}.column_Rh1Ve{flex-direction:column}.ghost_tbRI3{border:1px dashed var(--color-supportive-3-400);opacity:.2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column": "column_Rh1Ve",
	"row": "row_cxV5E",
	"ghost": "ghost_tbRI3"
};
module.exports = ___CSS_LOADER_EXPORT___;
