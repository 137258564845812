// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_KO3O3{flex:1}.legend_YrhO\\+{margin-right:.5rem}.legendColor_\\+iYFa{background-color:var(--legend-color);border-radius:50%;height:.5rem;width:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_KO3O3",
	"legend": "legend_YrhO+",
	"legendColor": "legendColor_+iYFa"
};
module.exports = ___CSS_LOADER_EXPORT___;
